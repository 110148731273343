import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';
import * as TYPES from 'redux/types';

import {auth, instance} from 'redux/modules/auth';
import {employerReducer} from 'redux/modules/employer/modules/reducer';

import {
  calendarReducer,
  userReducer,
  requesttypeReducer,
  demographicReducer,
  leadmapReducer,
} from 'redux/modules/outreach';

import {
  homepageReducer,
  // dashboardReducer,
  programsEnrolledReducer,
  programActivityGridReducer,
  notificationSettingsReducer,
} from 'redux/modules/homepage';

import {
  accountTypeReducer,
  departmentReducer,
  institutionTypeReducer,
  institutionFacultyReducer,
  institutionReducer,
  institutionFacultyTypeReducer,
  profile,
} from 'redux/modules/administration';

import {srmReportsReducers} from 'redux/modules/reports';
import {generalReducer} from './modules/general/reducers';
import {pathwaysReducers} from 'redux/modules/pathways';
import {studentManagementReducers} from 'redux/modules/studentManagement';
import {courseManagementReducers} from 'redux/modules/courseManagement';
import {srmPartnersReducers} from 'redux/modules/partners';
import {businessPartnerAdmin} from 'redux/modules/businessPartner/modules/administration';
import {superUserAdmin} from 'redux/modules/superUser/modules/administration';
import {reporterAppReducer} from 'redux/modules/reporterPortal/modules/';
import {consortium as consortiumAppReducer} from 'redux/modules/consortiumPortal/modules/administration';
import {permissionsReducer} from 'redux/modules/permissions';
import {srmStrongWorkforceReducers} from 'redux/modules/strongworkforce';
import {staticData} from 'redux/modules/staticData';

import newInstituteDashboard from 'redux/modules/reporterPortal/modules/institution_metrics';

const srmAppReducer = combineReducers({
  auth, // Auth
  instance, //instance
  staticData, // states
  accountType: accountTypeReducer,
  calendar: calendarReducer, // events
  dashboard: newInstituteDashboard, //  home dashboard
  programsEnrolled: programsEnrolledReducer,
  programActivityGrid: programActivityGridReducer,
  department: departmentReducer,
  demographic: demographicReducer,
  facultyType: institutionFacultyTypeReducer,
  homepage: homepageReducer,
  institutionType: institutionTypeReducer,
  institutionFaculty: institutionFacultyReducer,
  institution: institutionReducer,
  leadmap: leadmapReducer,
  notificationSettings: notificationSettingsReducer,
  pathways: pathwaysReducers,
  partners: srmPartnersReducers,
  permissions: permissionsReducer,
  requesttype: requesttypeReducer,
  routerReducer,
  studentManagement: studentManagementReducers,
  strongWorkforce: srmStrongWorkforceReducers,
  courseManagement: courseManagementReducers,
  user: userReducer,
  businessPartnerAdmin,
  profile,
  reports: srmReportsReducers,
  general: generalReducer,
});

const superUserAppReducer = combineReducers({
  auth,
  instance, //instance
  staticData,
  superUserAdmin,
  general: generalReducer,
});

const buinessPartnerAppReducer = combineReducers({
  auth,
  instance, //instance
  staticData,
  accountType: accountTypeReducer,
  calendar: calendarReducer,
  // dashboard: dashboardReducer,
  department: departmentReducer,
  facultyType: institutionFacultyTypeReducer,
  institution: institutionReducer,
  institutionType: institutionTypeReducer,
  institutionFaculty: institutionFacultyReducer,
  leadmap: leadmapReducer,
  notificationSettings: notificationSettingsReducer,
  pathways: pathwaysReducers,
  user: userReducer,
  businessPartnerAdmin,
  courseManagement: courseManagementReducers,
  studentManagement: studentManagementReducers,
  general: generalReducer,
  employer: employerReducer,
});

const reporterAppCombineReducer = combineReducers({
  auth,
  instance, //instance
  staticData,
  user: userReducer,
  reporterAppReducer,
  pathways: pathwaysReducers,
  reports: srmReportsReducers,
  general: generalReducer,
});

const consortiumAppCombineReducer = combineReducers({
  auth,
  instance, //instance
  staticData,
  profile,
  dashboard: newInstituteDashboard,
  accountType: accountTypeReducer,
  user: userReducer,
  calendar: calendarReducer,
  leadmap: leadmapReducer,
  department: departmentReducer,
  facultyType: institutionFacultyTypeReducer,
  institution: institutionReducer,
  institutionType: institutionTypeReducer,
  institutionFaculty: institutionFacultyReducer,
  notificationSettings: notificationSettingsReducer,
  partners: srmPartnersReducers,
  consortiumAppReducer,
  businessPartnerAdmin,
  pathways: pathwaysReducers,
  reports: srmReportsReducers,
  general: generalReducer,
});

const defaultReducer = combineReducers({
  auth,
  instance, //instance
  general: generalReducer,
});

const rootReducer = (state, action) => {
  if (action.type === TYPES.SIGNOUT) {
    // state = undefined;
  }

  switch (
    state &&
      state.auth &&
      state.auth.user_portal &&
      state.auth.user_portal.toLowerCase()
  ) {
    case 'srm':
    case 'school':
    case 'institution':
    case 'criminal_justice':
      return srmAppReducer(state, action);
    case 'reporter':
      return reporterAppCombineReducer(state, action);
    case 'consortium':
      return consortiumAppCombineReducer(state, action);
    case 'business_partner':
    case 'nonprofits':
      return buinessPartnerAppReducer(state, action);
    case 'super_user':
      return superUserAppReducer(state, action);
    default:
      return defaultReducer(state, action);
  }
};

export default rootReducer;
